import { formsHttpClient } from "../api";

const getForms = async pid => {
  let result = "";
  await formsHttpClient.get(`/forms/portal/${pid}`).then(response => {
    result = response.data;
  });

  return result;
};

const getFormById = async id => {
  let result = "";

  await formsHttpClient.get(`/form/${id}`).then(response => {
    result = response.data;
  });

  return result;
};

const submitForm = async (pid, id, data) => {
  let result = "";

  await formsHttpClient
    .post(`/form/submit/${pid}/${id}`, data, { timeout: 60000 })
    .then(response => {
      result = response.data;
    });

  return result;
};

const uploadImage = async (pid, formId, fieldId, formData) => {
  let result = "";

  await formsHttpClient
    .post(`/form/upload_image/${pid}/${formId}/${fieldId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 60000
    })
    .then(response => {
      result = response.data;
    });

  return result;
};

const printForm = async (formId, formData) => {
  let result = "";

  await formsHttpClient
    .post(`/form/print/${formId}`, formData)
    .then(response => {
      result = response.data;
    });

  return result;
};

export default {
  getForms,
  getFormById,
  submitForm,
  uploadImage,
  printForm
};
