<template>
  <div id="app">
    <app-header @log-out="logout" />
    <router-view />
  </div>
</template>

<script>
  import AppHeader from "./components/common/Header";
  import HealthService from "./services/HealthService";
  import airbrake from "./logging/logging";
  import config from "./logging/config";

  export default {
    components: { AppHeader },
    mounted() {
      HealthService.endpointStatus().then(response =>
        console.log("The spring app is:", response)
      );

      if (this.$store.getters.browserIsInternetExplorer) {
        // It was working, but now it's broke?
        // https://github.com/vuejs/vue-router/issues/1849
        window.addEventListener(
          "hashchange",
          function() {
            var currentPath = window.location.hash.slice(1);
            if (this.$route.path !== currentPath) {
              this.$router.replace(currentPath);
            }
          }.bind(this),
          false
        );
      }

      airbrake.addFilter(notice => {
        notice.params.sessionId = config.sessionId;
        notice.params.commitHash = config.appVersion;
        notice.params.appId = config.appId;
        return notice;
      });
    },
    methods: {
      logout() {
        this.$store.commit("setMember", null);
        this.$store.commit("setProviderId", null);
      }
    }
  };
</script>

<style lang="scss">
  @import "/assets/styles/_imports.scss";
  @import "~updox-styles/src/global/style/main.css";

  html,
  body {
    height: 100%;
    background: #f4f6f8;
  }

  #app {
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
