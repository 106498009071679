<template>
  <div class="forms-disabled-route">
    <info-icon size="4.0x" class="info-icon small-buffer" />
    <div v-if="!info.member && info.formId" class="info-content">
      <h2 class="small-buffer">
        Sorry, <strong>{{ info.formTitle }}</strong> is unavailable
      </h2>
      <p class="small-buffer">
        Please contact, <strong>{{ info.practiceName }}</strong> for assistance
        about your missing form.
      </p>
      <ul>
        <li>
          Form Id: <strong>{{ info.formId }}</strong>
        </li>
      </ul>
    </div>
    <div v-else-if="!info.member" class="info-content">
      <h2 class="small-buffer">Forms have been disabled</h2>
      <p class="small-buffer">
        Please contact, <strong>{{ info.practiceName }}</strong> for assistance
        about your missing forms.
      </p>
    </div>
    <div v-else class="info-content">
      <h2 class="small-buffer">Forms have been disabled</h2>
      <p class="small-buffer">
        Please contact your Administrator
        <span v-if="info.accountHolderFullName"
          >({{ info.accountHolderFullName }})</span
        >
        for assistance. Have them coordinate with Updox, if necessary.
      </p>
    </div>
  </div>
</template>

<script>
  import { InfoIcon } from "vue-feather-icons";
  export default {
    name: "UpdoxInfo",
    components: { InfoIcon },
    props: {
      infoDetails: {
        member: false,
        pid: null,
        practiceName: "",
        id: null,
        title: "",
        // 'single': Denotes a single form where a 412 PURCHASE_REQIURED WAS ENCOUNTERED
        accountHolderFullName: null
      }
    },
    computed: {
      info: function() {
        return {
          member: this.infoDetails?.member || false,
          pid: this.infoDetails?.pid || 0,
          /*  Maybe need a call to get basic information, even if Forms Module is disabled. */
          practiceName: this.infoDetails?.practiceName || "",
          formId: this.infoDetails?.id || 0,
          formTitle: this.infoDetails?.title || "",
          accountHolderFullName: this.infoDetails?.accountHolderFullName || ""
        };
      }
    }
  };
</script>

<style scoped lang="scss">
  .forms-disabled-route {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;

    .info-content {
      display: inherit;
      justify-content: inherit;
      align-items: inherit;
      flex-direction: inherit;
    }
  }

  .info-icon {
    color: blue;
  }
  .strong-highlight {
    background-color: yellow;
  }

  .small-buffer {
    margin-bottom: 5px;
  }
</style>
