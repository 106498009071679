<template>
  <div class="flex-container">
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="checkListError"
    />
    <label
      class="updox-checkbox"
      v-for="(opt, index) in field.list"
      :id="'label-' + field.id + '-' + index"
      :key="index"
      :for="field.id + '-' + index"
    >
      <input
        :value="opt"
        type="checkbox"
        class="check-list-input"
        :id="field.id + '-' + index"
        :aria-describedby="field.id + 'Help'"
        v-model="checkedValues"
        :name="field.id"
        :required="required"
        @change="updateValue(index)"
      />
      <span class="checkbox" :class="{ 'error-check': checkListError }">
        <check-icon size="18" class="check-icon" />
      </span>
      {{ opt }}
    </label>
    <div>
      <input-error v-if="checkListError" />
      <field-descriptor v-if="field.description" :field="field" />
    </div>
  </div>
</template>

<script>
  import { CheckIcon } from "vue-feather-icons";
  import FieldDescriptor from "./FieldDescriptor";
  import LabelField from "./LabelField";
  import InputError from "../common/InputError";
  export default {
    name: "CheckListField",
    components: { InputError, CheckIcon, LabelField, FieldDescriptor },
    data: () => ({
      checkedValues: [],
      required: false,
      checkListError: false
    }),
    mounted() {
      this.required = this.field.required;
    },
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        list: [],
        value: null
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateValue: function(index) {
        let checkbox = document.getElementById(`${this.field.id}-${index}`);
        let label = document.getElementById(`label-${this.field.id}-${index}`);

        if (checkbox && label) {
          label.className = checkbox.checked
            ? "updox-checkbox checked"
            : "updox-checkbox";
        }

        // In some older cases, the checklist was used to get around the fact you couldn't require a checkbox
        // So people just created checklists with one empty value, using the label to denote what the box meant
        if (this.field.list.length === 1 && this.field.list[0] === "") {
          let checkedVal = checkbox.checked ? ["Yes"] : ["No"];
          this.field.value = checkedVal;
          this.field.list = checkedVal;
        } else {
          this.field.value =
            this.checkedValues.length > 0 ? this.checkedValues : null;
        }

        this.required = this.field.required && this.checkedValues.length === 0;

        this.checkListError = this.required;
      }
    },
    watch: {
      showError() {
        this.checkListError =
          this.showError &&
          this.field.required &&
          this.checkedValues.length === 0;
      }
    }
  };
</script>

<style scoped lang="scss">
  .flex-container {
    flex-direction: column;
  }

  .updox-checkbox {
    align-items: center;
    display: inline-flex;
    margin-bottom: 10px;
    position: relative;

    > input {
      opacity: 0;
      position: absolute;
      height: 18px;
      width: 18px;
      top: 0;
      left: 0;

      &:focus {
        + .checkbox {
          box-shadow: 0 0 5px rgba(0, 174, 239, 0.35);
          border-color: #007fad;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;

      input:not(:checked) ~ .checkbox {
        border: 1px solid #6d777e;
      }
    }

    .check-icon {
      height: 18px;
      width: 18px;
    }

    .checkbox {
      line-height: 1em;
      border: 1px solid #aeb7bf;
      border-radius: 2px;
      display: block;
      margin-right: 5px;
      transition: all 0.2s ease-in-out;

      &.error-check {
        border-color: #cc0000 !important;
      }

      svg {
        opacity: 0;
        display: block;
      }
    }

    &.checked {
      .checkbox {
        background: #007fad;
        border-color: #007fad;
        color: #fff;

        svg {
          opacity: 1;
        }
      }

      > input:focus + .checkbox {
        box-shadow: 0 0 10px rgba(0, 174, 239, 0.65);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
</style>
