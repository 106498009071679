<template>
  <div>
    <div class="field-column">
      <label-field
        :is-small-label="false"
        :field="field"
        :label-error="dateboxError"
      />
      <input
        v-if="this.$store.getters.browserIsInternetExplorer"
        :aria-describedby="field.id + 'Help'"
        :required="field.required"
        class="date-picker"
        :class="{ 'error-class-input': dateboxError }"
        :id="field.id"
        placeholder="YYYY-MM-DD"
        type="text"
        @focusout="evaluateDateBoxError"
      />
      <date-picker
        v-else
        mode="single"
        v-model="dateValue"
        :min-date="minDate"
        :popover="{ visibility: 'click' }"
        color="blue"
      >
        <input
          @focusout="evaluateDateBoxError"
          @keyup="determineActualDateVal($event)"
          maxlength="10"
          placeholder="MM/DD/YYYY"
          :id="field.id"
          :required="field.required"
          type="input"
          slot-scope="{ inputProps, inputEvents }"
          class="date-picker"
          :class="{ 'error-class-input': dateboxError }"
          v-bind="inputProps"
          v-on="inputEvents"
          :aria-describedby="field.id + 'Help'"
        />
      </date-picker>
      <div>
        <input-error v-if="dateboxError" />
        <field-descriptor v-if="field.description" :field="field" />
      </div>
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import moment from "moment";
  import DatePicker from "v-calendar/lib/components/date-picker.umd";
  import InputError from "../common/InputError";
  import $ from "jquery";

  export default {
    name: "DateBoxField",
    components: {
      InputError,
      LabelField,
      FieldDescriptor,
      DatePicker
    },
    data: () => ({
      dateValue: null,
      minDate: new Date(1900, 1, 1),
      dateboxError: false
    }),
    mounted() {
      if (this.$store.getters.browserIsInternetExplorer) {
        let self = this;
        $(`#${this.field.id}`).datepicker({
          minDate: self.minDate,
          dateFormat: "yy-mm-dd",
          onSelect: function(date) {
            self.dateValue = date;
            self.field.value = date;
          }
        });
      }
    },
    beforeDestroy: function() {
      if (this.$store.getters.browserIsInternetExplorer) {
        $(`#${this.field.id}`)
          .datepicker("hide")
          .datepicker("destroy");
      }
    },
    computed: {
      normalizedDateValue: function() {
        if (this.dateValue) {
          let date = moment(this.dateValue, "YYYY-MM-DD")
            .toISOString()
            .split("T")[0];

          if (date.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)) {
            return date;
          }
        }
        return null;
      }
    },
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: ""
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateDateboxValue: function() {
        this.field.value = this.normalizedDateValue;
      },
      evaluateDateBoxError: function() {
        this.dateboxError = !!(this.field.required && !this.dateValue);
      },
      determineActualDateVal: function(event) {
        let backspace = 8;
        let deleteKey = 46;
        let keyCode = event.keyCode;
        let value = event.key;

        let manualDateVal = document.getElementById(`${this.field.id}`).value;

        if (
          value &&
          (value.match(/[0-9]/) ||
            keyCode === backspace ||
            keyCode === deleteKey)
        ) {
          if (
            (manualDateVal.length === 2 || manualDateVal.length === 5) &&
            keyCode !== backspace &&
            keyCode !== deleteKey
          ) {
            return (document.getElementById(`${this.field.id}`).value += "/");
          }
        }
      }
    },
    watch: {
      dateValue: function(newVal) {
        if (this.field.required && newVal) {
          this.dateboxError = false;
        }

        this.field.value = this.normalizedDateValue;
      },
      showError() {
        this.dateboxError =
          this.showError && this.field.required && !this.dateValue;
      }
    }
  };
</script>

<style scoped lang="scss">
  .field-column {
    display: flex;
    flex-direction: column;
    flex: 0.5;
  }
</style>
