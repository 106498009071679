<template>
  <div>
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="imageError"
    />
    <div class="form-group">
      <div v-if="loading" class="loading-wrapper">
        <updox-loading color="#003462" size="50px" />
      </div>
      <img
        class="preview"
        :src="previewImage"
        v-if="previewImage !== null"
        :alt="field.id"
      />
      <div class="file btn btn-lg btn-primary">
        <div
          class="file-upload-wrapper"
          :data-text="image.name ? image.name : 'Nothing selected'"
        >
          <updox-button
            class="image-button"
            v-if="!previewImage"
            type="button"
            :id="`choose-button-${field.id}`"
            :disabled="loading"
            :aria-disabled="loading"
          >
            Choose Image
          </updox-button>
          <updox-button
            class="clear-image"
            type="button"
            v-if="previewImage"
            priority="secondary"
            :id="`clear-button-${field.id}`"
            @click="clearImage()"
          >
            Clear Image
          </updox-button>
          <input
            class="upload-image"
            accept="image/png, image/jpeg"
            type="file"
            :id="field.id"
            :name="`file_${field.id}`"
            :aria-describedby="field.id + 'Help'"
            :required="field.required"
            :disabled="loading || previewImage"
            :aria-disabled="loading || previewImage"
          />
        </div>
        <input-error
          v-if="imageError"
          :value="image.status"
          :required="field.required"
        />
      </div>
    </div>
    <field-descriptor :field="fieldDescription" />
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  import { UpdoxButton, UpdoxLoading } from "@updox/common-vue";
  import FormsService from "../../services/FormsService";
  import airbrake from "@/logging/logging";

  export default {
    name: "ImageField",
    components: {
      InputError,
      LabelField,
      FieldDescriptor,
      UpdoxButton,
      UpdoxLoading
    },
    data: () => ({
      fileInput: null,
      boundUploadFunc: null,
      supressRequiredFunc: null,
      previewImage: null,
      image: {
        error: false,
        maxSize: 10485760,
        name: null,
        size: "",
        status: null
      },
      loading: false,
      genericErrorMessage:
        "Something went wrong when trying to upload this image. Please try again."
    }),
    props: {
      pid: null,
      formId: null,
      field: {
        id: "id",
        label: "label",
        required: false,
        fieldType: "type",
        description: "description",
        value: ""
      },
      showError: {
        value: false
      }
    },
    mounted() {
      this.fileInput = document.getElementById(`${this.field.id}`);
      this.boundUploadFunc = this.uploadImage.bind(this);
      this.fileInput.addEventListener("change", this.boundUploadFunc);
      if (this.field.required) {
        this.supressRequiredFunc = this.supressRequiredPopup.bind(this);
        this.fileInput.addEventListener("invalid", this.supressRequiredFunc);
      }
    },
    beforeDestroy() {
      this.field.value = "";
      this.fileInput.removeEventListener("change", this.boundUploadFunc);
      if (this.supressRequiredFunc) {
        this.fileInput.removeEventListener("invalid", this.supressRequiredFunc);
      }
    },
    methods: {
      uploadImage(event) {
        const image = event.target.files[0];
        if (!image || !image.size || !this.isValidFile(image, this.field.id)) {
          return;
        }

        this.image.status = null;
        this.image.error = false;
        this.loading = true;
        this.image.name = image.name;

        let blob = image;
        let formData = new FormData();
        formData.append("file", blob);
        FormsService.uploadImage(this.pid, this.formId, this.field.id, formData)
          .then(result => {
            this.previewImage = result.url;
            delete result.url;
            this.field.value = JSON.stringify(result);
            this.loading = false;
            this.image.error = false;
          })
          .catch(err => {
            this.setImageErrorState(this.field.id, this.genericErrorMessage);
            console.log(err);
            airbrake.notify({
              error: err,
              fieldId: this.fieldId,
              message: "Error loading form"
            });
          });
      },
      setImageErrorState(fieldId, status) {
        this.image.status = status;
        this.loading = false;
        this.image.error = true;
        this.clearImage();
      },
      isValidFile(file, fieldId) {
        this.image.size = file.size;

        if (this.image.size > this.image.maxSize) {
          this.image.error = true;

          const status = `File size cannot exceed 10MB. Please select a different image.`;
          this.setImageErrorState(fieldId, status);
          return false;
        } else {
          return true;
        }
      },
      clearImage() {
        this.fileInput.value = null;

        this.previewImage = null;
        this.field.value = null;
        this.image.name = null;
      },
      supressRequiredPopup(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileInput.focus();
      }
    },
    computed: {
      imageError() {
        return this.image.error;
      },
      fieldDescription() {
        let copyOfField = this.field;

        if (copyOfField.description) {
          copyOfField.description += " Image size must be less than 10MB.";
        } else {
          copyOfField.description = "Image size must be less than 10MB.";
        }

        return copyOfField;
      }
    },
    watch: {
      showError() {
        this.image.error =
          this.showError && this.field.required && !this.field.value;
      }
    }
  };
</script>

<style scoped lang="scss">
  @media only screen and (min-width: 653px) {
    img.preview {
      max-width: 600px;
      height: auto;
    }
  }

  @media only screen and (max-width: 652px) {
    img.preview {
      max-width: 100%;
      height: auto;
    }
  }

  .loading-wrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 50px;
    min-width: 50px;
    padding: 10px;
  }

  .file-upload-wrapper {
    align-items: center;
    display: flex;
    max-width: 400px;
    position: relative;
    width: 100%;

    .image-button {
      margin-right: 10px;
      z-index: 25;
    }

    input {
      display: block;
      bottom: 0;
      left: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      width: 100%;
      z-index: 99;
    }

    &:after {
      content: attr(data-text);
      pointer-events: none;
      z-index: 20;
      font-weight: 300;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    button {
      margin-right: 10px;
      z-index: 100; // trickery to make this button live "above" the clickable area of the file input
    }
  }

  @media (max-width: 768px) {
    button {
      font-size: 12px;
    }
  }
</style>
