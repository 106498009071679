<template>
  <a
    class="link"
    :id="`open-form-in-current-window-${form.id}`"
    :href="`${standaloneFormsAppUrl}`"
    :title="`Open ${form.title} in current window`"
    target="_self"
  >
    <file-icon size="1.5x" /><span class="padding">Open form</span>
  </a>
</template>

<script>
  import { FileIcon } from "vue-feather-icons";

  export default {
    name: "OpenFormInCurrentWindow",
    components: { FileIcon },
    props: {
      form: {
        title: "",
        id: 0
      }
    },
    computed: {
      standaloneFormsAppUrl: function() {
        return `/#/form/${this.form.id}`;
      }
    }
  };
</script>

<style scoped lang="scss">
  .link {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    font-size: 13px;
  }

  .padding {
    padding-left: 5px;
  }
</style>
