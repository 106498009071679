<template>
  <div class="error">
    You have <strong>{{ requiredFieldCount }}</strong> required fields to fill
    out.
    <a href="" @click.prevent="showHide" v-if="shouldNotShowErrors"
      >Click here to show them.</a
    >
    <a href="" @click.prevent="showHide" v-else>Click here to hide them.</a>
  </div>
</template>

<script>
  export default {
    name: "RequiredFieldsCounter",
    data: () => ({
      shouldNotShowErrors: true
    }),
    props: {
      requiredFieldCount: {
        value: 0
      }
    },
    methods: {
      showHide: function() {
        this.shouldNotShowErrors = !this.shouldNotShowErrors;

        this.$emit("error-change", this.shouldNotShowErrors);
      }
    }
  };
</script>

<style  scoped lang="scss">
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .error {
    color: #cc0000;
    margin-top: 10px;
    font-size: 10px;
    font-style: italic;
  }
</style>
