<template>
  <div class="footer">
    <small class="form-text text-muted topped"
      >Updox v2016.0 &dot; &copy; 2020 Updox</small
    >
  </div>
</template>

<script>
  export default {
    name: "UpdoxFooter"
  };
</script>

<style scoped>
  .footer {
    text-align: center;
  }
</style>
