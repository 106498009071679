<template>
  <div class="header">
    <div class="primary">
      <div class="logo">
        <router-link
          v-if="this.$store.getters.memberIsValid"
          :to="`/forms/${this.$store.state.member.pid}`"
          title="Updox"
        >
          <updox-logo />
        </router-link>
        <router-link
          v-else-if="this.$store.getters.providerIdIsValid"
          :to="`/forms/${this.$store.state.pid}`"
          title="Updox"
        >
          <updox-logo />
        </router-link>
        <updox-logo v-else />
      </div>
    </div>
    <div class="right">
      <updox-button
        priority="subtle"
        title="Open Updox Inbox"
        v-if="memberIsValid && false"
      >
        Inbox
      </updox-button>
      <!-- @TODO add to updox-common-vue or ui-components or whatever -->
      <a
        href="https://community.updox.com"
        title="Open The Updox Community"
        class="link-button"
        target="_blank"
        v-if="memberIsValid"
      >
        <updox-icon class="updox-icon" type="help-circle" stroke="#007fad" />
      </a>
      <updox-button
        v-if="memberIsValid"
        priority="subtle"
        @click="logout"
        title="Logout"
      >
        <updox-icon class="updox-icon" type="log-out" stroke="#007fad" />
      </updox-button>
    </div>
  </div>
</template>

<script>
  import ActionType from "@/store/action-type";
  import { UpdoxButton, UpdoxIcon, UpdoxLogo } from "@updox/common-vue";
  import { mapGetters } from "vuex";

  export default {
    name: "Header",
    methods: {
      admin() {
        this.$router.push({ name: "Admin" });
      },
      logout() {
        this.$store.dispatch(ActionType.user.LOGOUT);
        this.$emit("log-out");
        console.log("Logging out...");
      }
    },
    computed: {
      ...mapGetters(["memberIsValid"])
    },
    components: {
      UpdoxButton,
      UpdoxIcon,
      UpdoxLogo
    }
  };
</script>

<style scoped lang="scss">
  .header {
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
    color: #333132;
    display: flex;
    height: 60px;
    min-height: 60px;
    // @TODO make this a common _variable for a parent "containerish" element's padding.
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;

    .primary {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .right {
      display: flex;
    }

    .updox-icon {
      height: 24px;
      width: 24px;
    }

    .link-button {
      padding: 8px 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #f4f6f8;
      }
    }
  }
</style>
