<template>
  <a
    :id="`copy-form-to-clipboard-${form.id}`"
    :title="`Copy ${text} to clipboard`"
    class="link"
    @click.prevent="copy"
  >
    <copy-icon size="1.5x" />
    <span class="padding"> Copy link to clipboard</span>
  </a>
</template>

<script>
  import { CopyIcon } from "vue-feather-icons";

  export default {
    name: "CopyToClipBoardLink",
    components: { CopyIcon },
    props: {
      form: {
        id: 0,
        title: ""
      }
    },
    computed: {
      text: function() {
        return this.$store.getters.publicFormUrl + this.form.id;
      }
    },
    methods: {
      copy() {
        let tempInput = document.createElement("input");
        tempInput.setAttribute(
          "style",
          "position: absolute; left: -1000px; top: -1000px"
        );
        tempInput.setAttribute("value", this.text);
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        console.log("Copied the text:", tempInput.value);
        document.body.removeChild(tempInput);
      }
    }
  };
</script>

<style scoped lang="scss">
  .link {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    font-size: 13px;
  }

  .padding {
    padding-left: 5px;
  }
</style>
