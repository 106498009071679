<template>
  <div>
    <b-navbar class="tool-bar-nav">
      <b-navbar-nav class="nav-flex-grow">
        <div class="table-filter">
          <label class="search-icon" for="text-form-search">
            <search-icon size="1.2x" />
          </label>
          <input
            type="input"
            id="text-form-search"
            placeholder="Filter by Form Name"
            v-model="searchFilter"
            @input="filterForm"
          />
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
  import { BNavbar, BNavbarNav } from "bootstrap-vue";
  import { SearchIcon } from "vue-feather-icons";
  export default {
    name: "ToolBar",
    data: () => ({
      searchFilter: ""
    }),
    components: {
      "b-navbar": BNavbar,
      "b-navbar-nav": BNavbarNav,
      SearchIcon
    },
    methods: {
      filterForm() {
        this.$emit("filter-value", this.searchFilter);
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "node_modules/bootstrap/scss/bootstrap";
  @import "node_modules/bootstrap-vue/src/index.scss";

  .nav-flex-grow {
    flex-grow: 1;
  }

  .input-width {
    width: 100%;
  }

  .tool-bar-nav {
    background: #f4f6f8 !important;
    margin-left: 13px;
    margin-right: 13px;
  }

  .table-filter {
    display: flex;
    align-items: center;
    flex-grow: 1;

    input {
      flex-grow: 1;
      height: 40px;
      margin: 3px;
      padding-left: 40px;
      border: none;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
      box-shadow: 0 0 3px #aeb7bf;
    }
  }

  .search-icon {
    cursor: pointer;
    left: 20px;
    position: absolute;
    top: 19px;
    margin-left: 10px;
  }
</style>
