<template>
  <div class="updox-label" :class="{ 'error-class-label': labelError }">
    <label :id="`label-${field.id}`" :for="field.id">
      {{ field.label
      }}<span
        class="required"
        :class="{ 'error-class-label': labelError }"
        v-if="field.required"
      >
        *</span
      >
    </label>
  </div>
</template>

<script>
  export default {
    name: "LabelField",
    props: {
      field: {
        label: "",
        required: false,
        id: ""
      },
      labelError: {
        value: false
      }
    }
  };
</script>

<style scoped>
  .updox-label {
    font-weight: 600;
    padding-bottom: 10px;
  }
  .error-class-label {
    color: #cc0000 !important;
  }
</style>
