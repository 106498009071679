<template>
  <div class="flex-container">
    <fieldset>
      <legend :class="{ 'legend-error': radioError }">
        {{ field.label
        }}<span
          v-if="field.required"
          class="required"
          :class="{ 'legend-error': radioError }"
          >*</span
        >
      </legend>
      <label
        class="updox-radio"
        v-for="(opt, index) in field.list"
        :id="'label-' + field.id + '-' + index"
        :key="index"
        :for="field.id + '-' + index"
      >
        <input
          :value="opt"
          type="radio"
          class="radio-list-input"
          :name="field.id"
          :id="field.id + '-' + index"
          :aria-describedby="field.id + 'Help'"
          v-model="radioValue"
          :required="field.required"
          @change="updateRadioList"
        />
        <span class="radio" :class="{ 'radio-error': radioError }"></span>
        {{ opt }}
      </label>
      <div>
        <input-error v-if="radioError" />
        <field-descriptor v-if="field.description" :field="field" />
      </div>
    </fieldset>
  </div>
</template>

<script>
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  export default {
    name: "RadioListField",
    components: { InputError, FieldDescriptor },
    data: () => ({
      radioValue: "",
      radioError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: "",
        list: []
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateRadioList: function() {
        this.field.value = this.radioValue;
        this.radioError = false;
      }
    },
    watch: {
      showError() {
        this.radioError =
          this.showError && this.field.required && !this.radioValue;
      }
    }
  };
</script>

<style scoped lang="scss">
  .flex-container {
    flex-direction: column;
  }

  .updox-radio {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    position: relative;
    margin-right: 5px;

    > input {
      opacity: 0;
      position: absolute;
      cursor: pointer;
      height: 20px;
      width: 20px;
      top: 0;
      left: 0;

      &:focus {
        + .radio {
          box-shadow: 0 0 5px rgba(0, 174, 239, 0.35);
          border-color: #007fad;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:checked {
        ~ .radio {
          transition: background 0.3s;

          &::after {
            opacity: 1;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;

      input:not(:checked) ~ .radio {
        border: 1px solid #6d777e;
      }
    }

    .radio {
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #aeb7bf;
      height: 20px;
      margin-right: 5px;
      transition: all 0.2s ease-in-out;
      width: 20px;
      &::after {
        border-radius: 50%;
        background-color: #007fad;
        content: "";
        left: 4px;
        height: 14px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transition: all 0.2s ease-in-out;
        width: 14px;
      }

      &.radio-error {
        border-color: #cc0000 !important;
      }
    }
  }

  legend {
    font-weight: 600;
    font-size: 14px;
  }

  .legend-error {
    color: #cc0000;
  }
</style>
