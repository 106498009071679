<template>
  <div class="error-route">
    <alert-circle-icon size="4.0x" class="alert-circle small-buffer" />
    <div v-if="error.errorType === 'emptyForm'" class="error-content">
      <h2 class="small-buffer">Sorry, there was a problem loading the form.</h2>
      <p class="small-buffer">
        Please contact <strong>{{ error.name }}</strong> with this information:
      </p>
      <ul>
        <li>
          Form Name: <strong>{{ error.title }}</strong>
        </li>
        <li>
          Form ID: <strong>{{ error.id }}</strong>
        </li>
      </ul>
    </div>
    <div v-else class="error-content">
      <h2 class="small-buffer">
        Sorry, an unexpected error occurred loading this content.
      </h2>
      <p class="small-buffer">
        We are aware there is an issue and are working to fix it.
      </p>
      <p class="small-buffer">
        Try <a href="" @click.prevent="refresh">refreshing the page</a>, if that
        does not work please come back later.
      </p>
    </div>
  </div>
</template>

<script>
  import { AlertCircleIcon } from "vue-feather-icons";
  export default {
    name: "UpdoxError",
    components: { AlertCircleIcon },
    props: {
      errorDetails: {
        formId: 0,
        formTitle: "",
        practiceName: "",
        // 'emptyForm': Denotes a single form error where there were no fields. No other types defined yet.
        errorType: ""
      }
    },
    computed: {
      error: function() {
        return {
          id: this.errorDetails?.formId || 0,
          title: this.errorDetails?.formTitle || "",
          name: this.errorDetails?.practiceName || "",
          errorType: this.errorDetails?.errorType || ""
        };
      }
    },
    methods: {
      refresh() {
        location.reload();
      }
    }
  };
</script>

<style scoped lang="scss">
  .error-route {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .error-route .error-content {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    flex-direction: inherit;
  }
  .alert-circle {
    color: red;
  }
  .strong-highlight {
    background-color: yellow;
  }

  .small-buffer {
    margin-bottom: 5px;
  }
</style>
