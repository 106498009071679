<template>
  <div class="flex-container">
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="selectedError"
    />
    <select
      class="custom-select"
      :class="{ 'error-class-input': selectedError }"
      :id="field.id"
      :aria-describedby="field.id + 'Help'"
      v-model="selected"
      :required="field.required"
      @change="updateDropDown"
      @focusout="evaluateSelectedError"
    >
      <option value="" selected disabled>-- Please Select --</option>
      <option v-for="(opt, index) in field.list" :key="index" :value="opt">{{
        opt
      }}</option>
    </select>
    <div>
      <input-error v-if="selectedError" />
      <field-descriptor v-if="field.description" :field="field" />
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  export default {
    name: "DropDownField",
    components: {
      InputError,
      LabelField,
      FieldDescriptor
    },
    data: () => ({
      selected: "",
      selectedError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: "",
        list: []
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateDropDown: function() {
        this.field.value = this.selected;
        this.selectedError = false;
      },
      evaluateSelectedError: function() {
        if (this.field.required && !this.selected) {
          this.selectedError = true;
        }
      }
    },
    watch: {
      showError() {
        this.selectedError =
          this.showError && this.field.required && !this.selected;
      }
    }
  };
</script>

<style scoped lang="scss">
  .flex-container {
    flex-direction: column;
  }

  .custom-select {
    background-image: linear-gradient(45deg, transparent 50%, #6d777e 50%),
      linear-gradient(135deg, #6d777e 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    cursor: pointer;

    @media (min-width: 768px) {
      max-width: 360px;
    }
  }

  .custom-select:focus {
    background-image: linear-gradient(45deg, #3c8caa 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #3c8caa 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
</style>
