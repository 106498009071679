<template>
  <div id="success-message" class="alert alert-success" role="alert">
    <check-circle-icon size="4.0x" class="check-circle small-buffer" />
    <h4 class="alert-heading">Success</h4>
    <p>
      <strong>{{ form.title }}</strong> has been successfully submitted to
      <strong>{{ form.practiceName }}</strong>
    </p>
    <hr />
    <updox-button class="return-to-form" @click="reloadForm">
      Return to form
    </updox-button>
  </div>
</template>

<script>
  import { UpdoxButton } from "@updox/common-vue";
  import { CheckCircleIcon } from "vue-feather-icons";

  export default {
    name: "SuccessMessage",
    components: {
      CheckCircleIcon,
      UpdoxButton
    },
    props: {
      form: {
        title: "",
        practiceName: ""
      }
    },
    methods: {
      reloadForm() {
        this.$emit("reload-form");
      }
    }
  };
</script>

<style scoped lang="scss">
  .check-circle {
    color: green;
  }

  .small-buffer {
    margin-bottom: 5px;
  }

  .return-to-form {
    margin-top: 5px;
  }
</style>
