import Vue from "vue";
import Vuex from "vuex";
import Bowser from "bowser";
import ActionType from "@/store/action-type";

Vue.use(Vuex);

const initialState = {
  member: null,
  pid: 0,
  formLoaded: false,
  publicFormUrl: window.location.hostname + "/#/form/"
};

const mutationType = {
  SET_LOGGING_OUT: "setLoggingOut",
  SET_MEMBER: "setMember",
  SET_PROVIDER_ID: "setProviderId"
};

const mutations = {
  setLoggingOut(state, isLoggingOut) {
    state.isLoggingOut = isLoggingOut;
  },
  setMember(state, member) {
    state.member = member;
  },
  setProviderId(state, pid) {
    state.pid = pid;
  },
  setFormLoaded(state, formLoaded) {
    state.formLoaded = formLoaded;
  }
};

const actions = {
  [ActionType.user.LOGOUT]({ commit }) {
    commit(mutationType.SET_LOGGING_OUT, true);
    window.location.replace(`${window.location.origin}/api/logout`);
    commit(mutationType.SET_LOGGING_OUT, false);
  }
};

const getters = {
  memberIsValid(state) {
    return state.member !== null;
  },
  memberIsNotValid(state) {
    return state.member === null;
  },
  providerIdIsValid(state) {
    return state.pid > 0;
  },
  formIsLoaded(state) {
    return state.formLoaded;
  },
  publicFormUrl(state) {
    return state.publicFormUrl;
  },
  browserIsInternetExplorer() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return (
      browser.getBrowserName(true) === "internet explorer" &&
      browser.getBrowserVersion().includes("11")
    );
  }
};

const modules = {};

export default new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  modules,
  getters
});
