<template>
  <div v-if="required" class="error">Field is required. {{ value }}</div>
  <div v-else class="error">
    {{ value }}
  </div>
</template>

<script>
  export default {
    name: "InputError",
    props: {
      required: Boolean,
      value: String
    }
  };
</script>

<style scoped>
  .error {
    color: #cc0000;
    font-size: 10px;
    font-style: italic;
    margin-top: 6px;
  }
</style>
