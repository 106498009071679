<template>
  <div class="form-deleted-route">
    <info-icon size="4.0x" class="info-icon small-buffer" />
    <h2 class="small-buffer">
      Sorry, <strong>{{ info.formTitle }}</strong> is not available
    </h2>
    <p class="small-buffer">
      Please contact, <strong>{{ info.practiceName }}</strong> for assistance
    </p>
    <ul>
      <li>
        Form Id: <strong>{{ info.formId }}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
  import { InfoIcon } from "vue-feather-icons";
  export default {
    name: "FormDeleted",
    components: { InfoIcon },
    props: {
      infoDetails: {
        pid: null,
        practiceName: "",
        id: null,
        title: ""
      }
    },
    computed: {
      info: function() {
        return {
          pid: this.infoDetails?.pid || 0,
          practiceName: this.infoDetails?.practiceName || "",
          formId: this.infoDetails?.id || 0,
          formTitle: this.infoDetails?.title || ""
        };
      }
    }
  };
</script>

<style scoped lang="scss">
  .form-deleted-route {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;

    .info-content {
      display: inherit;
      justify-content: inherit;
      align-items: inherit;
      flex-direction: inherit;
    }
  }

  .info-icon {
    color: blue;
  }
  .strong-highlight {
    background-color: yellow;
  }

  .small-buffer {
    margin-bottom: 5px;
  }
</style>
