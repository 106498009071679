<template>
  <div>
    <div>
      <span class="updox-label">External Link</span>
      <small>(opens in new window)</small>
    </div>
    <a
      :id="`link-${field.id}`"
      :href="field.initRoute"
      :title="field.label"
      class="field-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ field.label }}
    </a>
    <div v-if="field.description">
      <field-descriptor :field="field" />
    </div>
  </div>
</template>

<script>
  import FieldDescriptor from "./FieldDescriptor";

  export default {
    name: "LinkField",
    components: { FieldDescriptor },
    props: {
      // We should never be passing back a value. We never want to see a Link show up in the Inbox.
      field: {
        description: "",
        label: "",
        id: "",
        initRoute: ""
      }
    }
  };
</script>

<style scoped lang="scss">
  .field-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .updox-label {
    font-weight: 600;
  }

  small {
    color: #58585a;
  }
</style>
