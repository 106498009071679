<template>
  <div class="form-control">
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="sigPadError"
    />
    <canvas
      :id="field.id"
      :aria-describedby="field.id + 'Help'"
      :required="field.required"
      :class="{ 'error-class-input': sigPadError }"
      @mouseleave="setSignatureData"
      @touchend="setSignatureData"
    ></canvas>
    <div>
      <input-error v-if="sigPadError" />
      <field-descriptor v-if="field.description" :field="field" />
    </div>
    <div>
      <button class="btn btn-sm btn-secondary" v-on:click.prevent="clearSigPad">
        Clear
      </button>
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import SignaturePad from "signature_pad";
  import InputError from "../common/InputError";
  import MobileDetect from "mobile-detect";

  export default {
    name: "SignatureField",
    data: () => ({
      resizeFunction: null,
      canvasElem: null,
      signaturePad: null,
      sigPadError: false
    }),
    mounted() {
      this.canvasElem = document.querySelector(`#${this.field.id}`);
      this.signaturePad = new SignaturePad(this.canvasElem, {
        background: "white",
        penColor: "black"
      });

      let md = new MobileDetect(window.navigator.userAgent);
      let resizeFunc = this.resizeCanvas;
      if (!md.mobile()) {
        this.resizeFunction = resizeFunc;
        window.addEventListener("resize", this.resizeFunction);
      } else {
        // For mobile devices, window resizes happen sporadically
        // while scrolling (when those share link buttons appear)
        window.addEventListener("orientationchange", function() {
          resizeFunc();
          // After orientationchange, add a one-time resize event
          let afterOrientationChange = function() {
            window.removeEventListener("resize", afterOrientationChange);
            resizeFunc();
          };
          window.addEventListener("resize", afterOrientationChange);
        });
      }
      this.resizeCanvas();
    },
    beforeDestroy() {
      this.field.value = "";
      if (this.resizeFunction) {
        window.removeEventListener("resize", this.resizeFunction);
      }
    },
    methods: {
      resizeCanvas() {
        // signature_pad talks about low-and-high DPI devices, leave this for later.
        let canvas = this.canvasElem;
        let ratio = Math.max(window.devicePixelRatio || 1, 1);
        let origData = this.signaturePad.toData();
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        this.clearSigPad(true); // otherwise isEmpty() might return incorrect value

        this.signaturePad.fromData(origData);
        this.setSignatureData();
      },
      dataBoundingBox(data) {
        let xPoints = data.flatMap(d => d.points.flatMap(p => p.x));
        let yPoints = data.flatMap(d => d.points.flatMap(p => p.y));
        let minX = Math.min(...xPoints);
        let minY = Math.min(...yPoints);
        let maxX = Math.max(...xPoints);
        let maxY = Math.max(...yPoints);
        return { minX, minY, maxX, maxY };
      },
      canvasToImage(backgroundColor) {
        let origData = this.signaturePad.toData();
        let boundingBox = this.dataBoundingBox(origData);

        // Just use non-visible Copies using the max-bounding box
        // to save the data
        let canvas = document.createElement("canvas");
        canvas.width = boundingBox.maxX;
        canvas.height = boundingBox.maxY;

        let signaturePad = new SignaturePad(canvas, {
          background: "white",
          penColor: "black"
        });
        signaturePad.fromData(origData);

        //cache height and width
        let w = canvas.width;
        let h = canvas.height;

        let context = canvas.getContext("2d");

        if (backgroundColor) {
          //set to draw behind current content
          context.globalCompositeOperation = "destination-over";

          //set background color
          context.fillStyle = backgroundColor;

          //draw background / rect on entire canvas
          context.fillRect(0, 0, w, h);
        }

        return canvas.toDataURL();
      },
      clearSigPad(onResize) {
        let origValue = this.field.value;

        this.field.value = "";
        this.signaturePad.clear();

        if (!onResize || origValue) {
          if (this.field.required) {
            this.sigPadError = true;
          }
        }
      },
      setSignatureData() {
        // Simple setting of signature pad colors and saving as JPEG,
        // still resulted in no-background (transparency sort of image)
        // that did not work well for catalyst-thumbnails and image-editor
        //
        // Probably need to limit image-editor to just rotations, if
        // folderItem-form
        if (this.canvasIsNotBlank()) {
          this.field.value = this.canvasToImage("white");
          this.sigPadError = false;
        }
      },
      canvasIsNotBlank() {
        let origData = this.signaturePad.toData();
        let boundingBox = this.dataBoundingBox(origData);

        return boundingBox?.maxX > 0 && boundingBox?.maxY > 0;
      }
    },
    components: {
      InputError,
      LabelField,
      FieldDescriptor
    },
    props: {
      field: {
        id: "id",
        label: "label",
        required: false,
        description: "description",
        value: ""
      },
      showError: {
        value: false
      }
    },
    watch: {
      showError() {
        this.sigPadError =
          this.showError && this.field.required && !this.field.value;
      }
    }
  };
</script>

<style scoped lang="scss">
  .form-control {
    display: flex;
    flex-direction: column;
  }

  canvas {
    border: 1px solid #aeb7bf;
    background-color: #fff;
    border-radius: 2px;
    height: 170px;
    padding: 6px;
    max-width: 315px;
  }
</style>
