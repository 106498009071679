<template>
  <a
    :id="`print-form-${form.id}`"
    :title="`Print ${form.title}`"
    :class="[!isLoading ? 'link' : 'not-active']"
    @click.prevent="print"
  >
    <printer-icon size="1.5x" v-if="!isLoading" />
    <span class="padding" v-if="!isLoading"> Print Form</span>
    <b-spinner small v-if="isLoading" class="margin"></b-spinner>
    <span class="padding" v-if="isLoading">Printing...</span>
  </a>
</template>

<script>
  import { PrinterIcon } from "vue-feather-icons";
  import FormsService from "../../services/FormsService";
  import { BSpinner } from "bootstrap-vue";

  export default {
    name: "PrintForm",
    components: { PrinterIcon, BSpinner },
    data: () => ({
      printForm: null,
      cachedForms: [],
      isPrint: false,
      isLoading: false,
      isNotError: true
    }),
    props: {
      form: {
        fields: [],
        id: 0,
        pid: 0,
        title: "",
        practiceName: ""
      },
      isEmbedded: {
        value: false
      }
    },
    methods: {
      print: async function() {
        this.isPrint = true;
        this.isLoading = true;
        this.isNotError = true;

        if (this.isEmbedded) {
          this.printForm = this.form;
        } else {
          let formInCache = this.cachedForms.find(
            item => item.id === this.form.id
          );

          if (formInCache === undefined) {
            await FormsService.getFormById(this.form.id)
              .then(resp => {
                this.printForm = resp;
                this.cachedForms.push(resp);
              })
              .catch(e => {
                this.isLoading = false;
                this.$emit("print-fail");
                console.error(e);
                this.isNotError = false;
              });
          } else {
            this.printForm = formInCache;
          }
        }

        this.printForm.title = this.form.title;

        let formHtml = await FormsService.printForm(
          this.form.id,
          this.printForm
        ).catch(e => {
          console.error(e);
          this.isLoading = false;
          this.isNotError = false;
          this.$emit("print-fail");
        });

        if (this.isNotError) {
          let iFrame = document.createElement("IFRAME");
          iFrame.style.position = "absolute";
          iFrame.style.top = "-10000px";
          document.body.appendChild(iFrame);
          iFrame.contentDocument.write(formHtml);
          iFrame.focus();
          iFrame.contentWindow.print();
          iFrame.parentNode.removeChild(iFrame);
          window.focus();
        }

        this.isPrint = false;
        this.printForm = null;
        this.isLoading = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "node_modules/bootstrap/scss/bootstrap";
  @import "node_modules/bootstrap-vue/src/index.scss";

  .link {
    align-items: center;
    display: flex;
    flex-direction: row !important;
    font-size: 13px;
  }

  .padding {
    padding-left: 5px;
  }

  .no-show {
    position: absolute;
    top: -4000px;
    left: -4000px;
  }

  .not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row !important;
    font-size: 13px;
  }

  .margin {
    margin-left: 10px;
  }
</style>
