<template>
  <h4 class="topped">
    {{ field.label }}
  </h4>
</template>

<script>
  export default {
    name: "SectionField",
    props: {
      field: {
        label: "label"
      }
    }
  };
</script>

<style scoped></style>
