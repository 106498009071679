import { Notifier } from "@airbrake/browser";
import config from "./config";

let env = "";
if (window.location.hostname.match(/myupdox\.com/)) {
  env = "production";
} else if (window.location.hostname.match(/qa|perf|test/)) {
  env = "testing";
} else {
  env = "development";
}

const airbrake = new Notifier({
  environment: env,
  projectId: 308438,
  projectKey: "3aebfba6ec0e675647feff52bb6fc443"
});

airbrake.addFilter(notice => {
  notice.context.app = "forms";
  notice.context.version = config.appVersion;

  return notice;
});

export default airbrake;
