<template>
  <div class="rating">
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="ratingError"
    />
    <div>
      <star-rating
        v-model="ratingValue"
        :id="field.id"
        :required="field.required"
        :star-size="20"
        :max-rating="maxRating"
        @rating-selected="updateRating"
      />
    </div>
    <input-error v-if="ratingError" />
    <button class="btn btn-sm btn-secondary" @click.prevent="clearRating">
      Clear
    </button>
    <div>
      <field-descriptor v-if="field.description" :field="field" />
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import StarRating from "vue-star-rating";
  import InputError from "../common/InputError";
  export default {
    name: "RatingField",
    components: { InputError, LabelField, FieldDescriptor, StarRating },
    data: () => ({
      ratingValue: 0,
      ratingError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: 0,
        list: []
      },
      showError: {
        value: false
      }
    },
    methods: {
      clearRating() {
        this.ratingValue = 0;
        this.field.value = null;

        if (this.field.required) {
          this.ratingError = true;
        }
      },
      updateRating() {
        this.field.value = `${this.ratingValue}`;
        this.ratingError = false;
      }
    },
    computed: {
      maxRating() {
        let len = this.field.list.length;
        if (len !== undefined && len !== 0) {
          return len;
        } else {
          return 5;
        }
      }
    },
    watch: {
      showError() {
        this.ratingError =
          this.showError && this.field.required && !this.ratingValue;
      }
    }
  };
</script>

<style scoped></style>
