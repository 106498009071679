<template>
  <b-alert
    :show="alertDetails.show"
    class="position-fixed fixed-top m-0 rounded-0"
    style="z-index: 6000; top: 60px"
    :variant="alertDetails.showType"
    :id="`alert-banner-${alertDetails.showType}`"
    dismissible
    @dismissed="dismissAlert"
  >
    <span
      v-if="alertDetails.success && alertDetails.isFormError"
      id="success-message"
    >
      <strong>{{ form.title }}</strong> has been submitted to
      <strong>{{ form.practiceName }}</strong>
    </span>
    <span
      v-else-if="!alertDetails.success && alertDetails.isFormError"
      id="error-message"
    >
      Unable to submit <strong>{{ form.title }}</strong
      >. Please try again or reach out to
      <strong>{{ form.practiceName }}</strong> for assistance.
    </span>
    <span v-else>
      {{ alertDetails.defaultErrorMessage }}
    </span>
  </b-alert>
</template>

<script>
  import { BAlert } from "bootstrap-vue";
  export default {
    name: "AlertBanner",
    components: { BAlert },
    props: {
      form: {
        title: "",
        practiceName: ""
      },
      alertDetails: {
        show: false,
        showType: "",
        success: false,
        defaultErrorMessage: "",
        isFormError: false
      }
    },
    methods: {
      dismissAlert() {
        this.alertDetails.show = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "node_modules/bootstrap/scss/bootstrap";
  @import "node_modules/bootstrap-vue/src/index.scss";
</style>
