<template>
  <div id="router">
    <div v-if="loading" class="loading-wrapper">
      <updox-loading color="#003462" size="80px" />
    </div>
    <div v-else>
      <h1 class="not-needed">Just here for routing</h1>
    </div>
  </div>
</template>

<script>
  import MemberService from "../services/MemberService";
  import { UpdoxLoading } from "@updox/common-vue";
  import airbrake from "../logging/logging";

  export default {
    name: "LoginPage",
    components: { UpdoxLoading },
    data: () => ({
      loading: true
    }),
    mounted() {
      MemberService.getMember()
        .then(response => {
          const member = response;
          this.$store.commit("setMember", member);
          this.loading = false;
          this.$router.push(`/forms/${member.pid}`);

          airbrake.addFilter(notice => {
            notice.params.memberId = member.id;
            notice.params.role = "Participant";
            return notice;
          });
        })
        .catch(e => {
          let { isForwarding } = e;
          if (!isForwarding) {
            this.$router.push("/error");
            airbrake.notify({
              error: e,
              message: "Unable to process member login"
            });
          }
        });
    }
  };
</script>

<style scoped>
  .not-needed {
    visibility: hidden;
  }
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 10%;
  }
</style>
