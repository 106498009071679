<template>
  <div class="spacer">
    &nbsp;
  </div>
</template>

<script>
  export default {
    name: "SpacerField"
  };
</script>

<style scoped>
  .spacer {
    background: transparent !important;
  }
</style>
