<template>
  <small :id="field.id + 'Help'" class="form-text text-muted">{{
    field.description
  }}</small>
</template>

<script>
  export default {
    name: "FieldDescriptor",
    props: {
      field: {
        id: "",
        description: ""
      },
      showError: {
        value: false
      }
    }
  };
</script>

<style scoped>
  small {
    color: #58585a;
    font-size: 12px;
  }
</style>
