<template>
  <div>
    <div class="field-column">
      <label-field
        :is-small-label="false"
        :field="field"
        :label-error="textAreaError"
      />
      <textarea
        rows="5"
        class="textarea-control"
        :class="{ 'error-class-input': textAreaError }"
        :id="field.id"
        :aria-describedby="field.id + 'Help'"
        v-model="textAreaValue"
        :required="field.required"
        @input="updateTextArea"
        @focusout="checkTextAreaError"
      ></textarea>
      <input-error v-if="textAreaError" />
      <div>
        <field-descriptor v-if="field.description" :field="field" />
      </div>
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  export default {
    name: "TextAreaField",
    components: { InputError, LabelField, FieldDescriptor },
    data: () => ({
      textAreaValue: "",
      textAreaError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: ""
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateTextArea: function() {
        this.field.value = this.textAreaValue;
      },
      checkTextAreaError: function() {
        this.textAreaError = !!(
          this.field.required && !this.textAreaValue.trim()
        );
      }
    },
    watch: {
      showError() {
        this.textAreaError =
          this.showError && this.field.required && !this.textAreaValue.trim();
      }
    }
  };
</script>

<style scoped lang="scss">
  .field-column {
    display: flex;
    flex-direction: column;
    flex: 0.5;
  }
  .textarea-control {
    resize: none;
  }
</style>
