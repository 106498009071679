<template>
  <div>
    <div class="field-column">
      <label-field :for="field.id" :field="field" :label-error="textBoxError" />
      <input
        type="text"
        class="textbox-control"
        :class="{ 'error-class-input': textBoxError }"
        :id="field.id"
        :aria-describedby="field.id + 'Help'"
        v-model="textBoxValue"
        :required="field.required"
        @input="updateTextBoxValue"
        @focusout="checkTextBoxError"
      />
      <input-error v-if="textBoxError" />
      <div v-if="field.description">
        <field-descriptor :field="field" />
      </div>
    </div>
  </div>
</template>

<script>
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  export default {
    name: "TextBoxField",
    components: { InputError, LabelField, FieldDescriptor },
    data: () => ({
      textBoxValue: "",
      textBoxError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: ""
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateTextBoxValue: function() {
        this.field.value = this.textBoxValue;
      },
      checkTextBoxError: function() {
        this.textBoxError = !!(
          this.field.required && !this.textBoxValue.trim()
        );
      }
    },
    watch: {
      showError() {
        this.textBoxError =
          this.showError && this.field.required && !this.textBoxValue.trim();
      }
    }
  };
</script>

<style scoped lang="scss">
  .field-column {
    display: flex;
    flex-direction: column;
    flex: 0.5;
  }
</style>
