const short = require("short-uuid");

class Config {
  _sessionId = short.generate();
  _appId = "Forms";
  // eslint-disable-next-line no-undef
  _appVersion = process.env.VUE_APP_COMMIT_HASH || 0;

  set appId(value) {
    this._appid = value;
  }

  get appId() {
    return this._appid;
  }

  set appVersion(value) {
    this._appVersion = value;
  }

  get appVersion() {
    return this._appVersion;
  }

  get sessionId() {
    return this._sessionId;
  }
}

const config = new Config();
export default config;
