import { formsHttpClient } from "../api";
import airbrake from "../logging/logging";

const endpointStatus = async () => {
  //await api.get(`some-url/${pid}`);
  // Fake "loading time"
  let status = "";
  await formsHttpClient
    .get("/actuator/health")
    .then(response => {
      status = response.data.status;
    })
    .catch(err => {
      airbrake.notify({
        error: err,
        message: "Unable to communicate with spring app."
      });
      status = "DOWN [" + err.toString() + "]";
    });

  return status;
};

export default {
  endpointStatus
};
