<template>
  <div>
    <label-field
      :is-small-label="false"
      :field="field"
      :label-error="checkBoxError"
    />
    <label class="updox-checkbox" :class="{ checked: checked }" :for="field.id">
      <input
        value="true"
        type="checkbox"
        class="check-box-input"
        :id="field.id"
        :aria-describedby="field.id + 'Help'"
        v-model="checked"
        :required="field.required"
        @change="updateChecked"
      />
      <span class="checkbox" :class="{ 'error-check': checkBoxError }">
        <check-icon size="18" class="check-icon" />
      </span>
    </label>
    <div>
      <input-error v-if="checkBoxError" required="field.required" />
      <field-descriptor v-if="field.description" :field="field" />
    </div>
  </div>
</template>

<script>
  import { CheckIcon } from "vue-feather-icons";
  import LabelField from "./LabelField";
  import FieldDescriptor from "./FieldDescriptor";
  import InputError from "../common/InputError";
  export default {
    name: "CheckBoxField",
    components: { InputError, CheckIcon, LabelField, FieldDescriptor },
    data: () => ({
      checked: false,
      checkBoxError: false
    }),
    props: {
      field: {
        id: "",
        label: "",
        required: false,
        description: "",
        value: ""
      },
      showError: {
        value: false
      }
    },
    methods: {
      updateChecked() {
        this.field.value = this.checked;

        this.checkBoxError = !!(this.field.required && !this.field.value);
      }
    },
    watch: {
      showError() {
        this.checkBoxError =
          this.showError && this.field.required && !this.checked;
      }
    }
  };
</script>

<style scoped lang="scss">
  .updox-checkbox {
    display: inline-flex;
    position: relative;
    > input {
      opacity: 0;
      position: absolute;

      &:focus {
        + .checkbox {
          box-shadow: 0 0 5px rgba(0, 174, 239, 0.35);
          border-color: #007fad;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .check-icon {
      height: 18px;
      width: 18px;
    }

    .checkbox {
      line-height: 1em;
      border: 1px solid #aeb7bf;
      border-radius: 2px;
      display: block;
      margin-right: 5px;
      transition: all 0.2s ease-in-out;

      &.error-check {
        border-color: #cc0000 !important;
      }

      svg {
        opacity: 0;
        display: block;
      }
    }

    &.checked {
      .checkbox {
        background: #007fad;
        border-color: #007fad;
        color: #fff;

        svg {
          opacity: 1;
        }
      }

      > input:focus + .checkbox {
        box-shadow: 0 0 10px rgba(0, 174, 239, 0.65);
      }
    }

    &:hover {
      cursor: pointer;
    }

    .text {
      padding-left: 6px;
    }
  }
</style>
<style scoped lang="scss">
  .updox-checkbox {
    align-items: center;
    display: inline-flex;
    position: relative;

    small {
      margin-left: 8px;
    }

    > input {
      opacity: 0;
      position: absolute;
      height: 18px;
      width: 18px;
      top: 0;
      left: 0;

      &:focus {
        + .checkbox {
          box-shadow: 0 0 5px rgba(0, 174, 239, 0.35);
          border-color: #007fad;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;

      input:not(:checked) ~ .checkbox {
        border: 1px solid #6d777e;
      }
    }

    .check-icon {
      height: 18px;
      width: 18px;
    }

    .checkbox {
      line-height: 1em;
      border: 1px solid #aeb7bf;
      border-radius: 2px;
      display: block;
      margin-right: 5px;
      transition: all 0.2s ease-in-out;

      svg {
        opacity: 0;
        display: block;
      }
    }

    &.checked {
      .checkbox {
        background: #007fad;
        border-color: #007fad;
        color: #fff;

        svg {
          opacity: 1;
        }
      }

      > input:focus + .checkbox {
        box-shadow: 0 0 10px rgba(0, 174, 239, 0.65);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
</style>
