import { formsHttpClient } from "../api";

const getMember = async () => {
  let result = "";

  await formsHttpClient.get(`/member/info`).then(response => {
    result = response.data;
  });

  return result;
};

export default {
  getMember
};
