<template>
  <div class="form">
    <div v-if="loading" class="loading-wrapper">
      <updox-loading color="#003462" size="80px" />
    </div>
    <div v-else-if="error">
      <updox-error :error-details="errorDetails" />
    </div>
    <div v-else-if="formsModuleDisabled">
      <forms-app-disabled :info-details="infoDetails" />
    </div>
    <div v-else-if="formAppearsDeleted">
      <form-deleted :info-details="infoDetails" />
    </div>
    <div class="padding-20" v-else-if="submitted">
      <success-message
        class="form-container success-message"
        :form="form"
        @reload-form="reloadForm"
      />
    </div>
    <div v-else class="padding-20">
      <alert-banner :form="form" :alert-details="alertDetails" />
      <form
        class="form-container"
        :id="`form-${form.id}`"
        @submit.prevent="submitForm"
      >
        <br />
        <h2>
          {{ form.title }}
        </h2>
        <div class="form-body">
          <div
            v-for="field in form.fields"
            :key="field.id + field.type"
            class="form-field"
          >
            <text-box-field
              :id="`textbox-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'textbox'"
              :key="componentKey"
            />
            <signature-field
              :id="`signature-container-${field.id}`"
              :field="field"
              v-if="field.type === 'signature'"
              :show-error="errorChange"
              :key="componentKey"
            />
            <image-field
              :pid="`${form.pid}`"
              :form-id="`${form.id}`"
              :field="field"
              :id="`image-upload-container-${field.id}`"
              :show-error="errorChange"
              v-if="field.type === 'imageUpload'"
              :key="componentKey"
            />
            <text-area-field
              :field="field"
              :id="`textarea-container-${field.id}`"
              :show-error="errorChange"
              v-if="field.type === 'textarea' && !field.key"
              :key="componentKey"
            />
            <date-box-field
              :id="`datebox-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'datebox'"
              :key="componentKey"
            />
            <check-list-field
              :id="`checklist-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'checklist'"
              :key="componentKey"
            />
            <rating-field
              :id="`rating-field-container-${field.id}`"
              :field="field"
              v-if="field.type === 'rating'"
              :show-error="errorChange"
              :key="componentKey"
            />
            <radio-list-field
              :id="`radio-list-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'radiolist' && !field.key"
              :key="componentKey"
            />
            <drop-down-field
              :id="`dropdown-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'dropdown'"
              :key="componentKey"
            />
            <check-box-field
              :id="`checkbox-container-${field.id}`"
              :field="field"
              :show-error="errorChange"
              v-if="field.type === 'checkbox'"
              :key="componentKey"
            />
            <section-field
              :id="`section-container-${field.id}`"
              v-if="field.type === 'section'"
              :field="field"
            />
            <spacer-field
              :id="`spacer-container-${field.id}`"
              v-if="field.type === 'spacer'"
            />
            <label-field
              :is-small-label="true"
              v-if="field.type === 'label'"
              :field="field"
              :id="`label-field-container-${field.id}`"
            />
            <link-field
              :id="`link-container-${field.id}`"
              :field="field"
              v-if="field.type === 'link'"
              :key="componentKey"
            />
          </div>
        </div>
        <hr v-if="isNotPresentationalFieldsOnly" />
        <div class="form-actions" v-if="isNotPresentationalFieldsOnly">
          <small class="form-body" v-if="!embedded">
            <strong>{{ form.title }}</strong> will be submitted to
            <strong>{{ form.practiceName }}</strong>
          </small>
          <updox-button
            type="submit"
            id="submitFormButton"
            text="Submit"
            :disabled="submitDisabled || currentlySubmitting"
            :loading="currentlySubmitting"
          />
          <required-fields-counter
            :required-field-count="requiredCount"
            @error-change="showErrorFields"
            v-if="requiredCount !== 0"
          />
        </div>
        <br />
      </form>
    </div>
    <div class="padding-10">
      <updox-footer />
    </div>
  </div>
</template>

<script>
  import TextBoxField from "../components/form-elements/TextBoxField";
  import SignatureField from "../components/form-elements/SignatureField";
  import ImageField from "../components/form-elements/ImageField";
  import TextAreaField from "../components/form-elements/TextAreaField";
  import DateBoxField from "../components/form-elements/DateBoxField";
  import CheckListField from "../components/form-elements/CheckListField";
  import RatingField from "../components/form-elements/RatingField";
  import RadioListField from "../components/form-elements/RadioListField";
  import DropDownField from "../components/form-elements/DropDownField";
  import CheckBoxField from "../components/form-elements/CheckBoxField";
  import FormsService from "../services/FormsService";
  import { UpdoxLoading, UpdoxButton } from "@updox/common-vue";
  import SectionField from "../components/form-elements/SectionField";
  import SpacerField from "../components/form-elements/SpacerField";
  import UpdoxFooter from "../components/common/UpdoxFooter";
  import LabelField from "../components/form-elements/LabelField";
  import LinkField from "../components/form-elements/LinkField";
  import SuccessMessage from "../components/common/SuccessMessage";
  import UpdoxError from "../components/common/UpdoxError";
  import FormsAppDisabled from "../components/common/FormsAppDisabled";
  import FormDeleted from "../components/common/FormDeleted";
  import RequiredFieldsCounter from "../components/helpers/RequiredFieldsCounter";
  import AlertBanner from "../components/helpers/AlertBanner";
  import HealthService from "../services/HealthService";
  import airbrake from "../logging/logging";

  export default {
    name: "SingleForm",
    props: {
      formsData: {
        fields: [],
        id: 0,
        pid: 0,
        title: "",
        practiceName: ""
      }
    },
    data: () => ({
      embedded: false,
      loading: true,
      submitted: false,
      forms: null,
      error: false,
      errorDetails: null,
      formsModuleDisabled: false,
      formAppearsDeleted: false,
      infoDetails: null,
      submitDisabled: true,
      currentlySubmitting: false,
      requiredCount: 0,
      errorChange: false,
      keepAliveTimeoutId: null,
      keepSessionChecking: true,
      alertDetails: {
        show: false,
        showType: "hidden",
        success: false,
        isFormError: false,
        defaultErrorMessage: ""
      },
      componentKey: 0
    }),
    async mounted() {
      if (!this.$store.getters.formIsLoaded) {
        const formId = this.$route.params.formId;
        await FormsService.getFormById(formId)
          .then(response => {
            if (response.fields.length === 0) {
              this.loading = false;
              this.error = true;
              this.errorDetails = {
                formId: formId,
                formTitle: response.title,
                practiceName: response.practiceName,
                errorType: "emptyForm"
              };
            } else {
              this.forms = response;
              airbrake.addFilter(notice => {
                notice.params.formId = formId;
                return notice;
              });
            }
          })
          .catch(e => {
            let { formsModuleDisabled, formAppearsDeleted } = e;
            this.loading = false;

            if (formsModuleDisabled || formAppearsDeleted) {
              let responseJson = e?.response?.data;
              this.formsModuleDisabled = formsModuleDisabled;
              this.formAppearsDeleted = formAppearsDeleted;
              this.infoDetails = {
                ...responseJson
              };
            } else {
              this.error = true;
              console.error(`Error loading form ${formId}`, e);
              airbrake.notify({
                error: e,
                message: `Error loading form ${formId}`
              });
            }
          });

        let keepSessionAlive = function() {
          HealthService.endpointStatus()
            .then(result => {
              console.log(result);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              if (this.keepSessionChecking) {
                this.keepAliveTimeoutId = setTimeout(keepSessionAlive, 600000);
              }
            });
        }.bind(this);
        keepSessionAlive();
        this.loading = false;
      } else {
        this.loading = false;
      }

      this.requiredFieldCount();
      this.checkIfSubmittable();
    },
    beforeDestroy() {
      if (this.keepAliveTimeoutId) {
        this.keepSessionChecking = false;
        clearTimeout(this.keepAliveTimeoutId);
      }
    },
    computed: {
      form: function() {
        if (this.formsData !== undefined && this.formsData !== null) {
          this.sanitizeFormsData(this.formsData);
          return this.formsData;
        } else if (this.forms !== undefined && this.forms !== null) {
          this.sanitizeFormsData(this.forms);
          return this.forms;
        } else {
          return null;
        }
      },
      isInListView: function() {
        return this.formsData !== undefined && this.formsData !== null;
      },
      isNotPresentationalFieldsOnly: function() {
        let typeMap = this.form.fields
          .map(item => {
            return item.type;
          })
          .filter(item => {
            return (
              item !== "section" &&
              item !== "spacer" &&
              item !== "label" &&
              item !== "link"
            );
          });

        return typeMap.length !== 0;
      }
    },
    watch: {
      form: {
        handler() {
          this.checkIfSubmittable();
          this.requiredFieldCount();
        },
        deep: true
      },
      "form.id"() {
        this.reloadForm();
      }
    },
    methods: {
      submitForm() {
        this.currentlySubmitting = true;
        FormsService.submitForm(this.form.pid, this.form.id, this.form.fields)
          .then(response => {
            this.submitted = true;
            this.currentlySubmitting = false;
            console.log(response);
            this.componentKey += 1;
          })
          .catch(e => {
            this.submitted = false;
            this.currentlySubmitting = false;
            this.alertDetails = {
              show: true,
              showType: "danger",
              success: false,
              isFormError: true,
              defaultErrorMessage: "Unable to submit form."
            };
            console.error(`Error submitting form ${this.form.id}`, e);
            airbrake.notify({
              error: e,
              message: `Error submitting form ${this.form.id}`
            });
          });
      },
      reloadForm: function() {
        this.submitted = false;
        this.form.fields.forEach(item => (item.value = null));
        this.requiredFieldCount();
        this.checkIfSubmittable();
      },
      sanitizeFormsData(formData) {
        formData.fields.forEach(item => {
          // So far key and desc can be undefined
          if (!item.key) {
            item.key = "";
          }
          if (!item.description) {
            item.description = "";
          }
        });
      },
      checkIfSubmittable() {
        this.submitDisabled =
          this.form !== null &&
          this.form.fields !== undefined &&
          this.form.fields.find(
            element => element.required && !element.value
          ) !== undefined;
      },
      requiredFieldCount() {
        // this.requiredCount =
        if (this.form !== null && this.form.fields !== undefined) {
          this.requiredCount = this.form.fields.filter(
            element => element.required && !element.value
          ).length;
        }
      },
      showErrorFields(value) {
        // true: show errors, false hide them
        this.errorChange = !value;
      }
    },
    components: {
      LinkField,
      AlertBanner,
      RequiredFieldsCounter,
      FormsAppDisabled,
      FormDeleted,
      UpdoxError,
      SuccessMessage,
      UpdoxFooter,
      LabelField,
      SpacerField,
      SectionField,
      UpdoxLoading,
      UpdoxButton,
      CheckBoxField,
      DropDownField,
      RadioListField,
      RatingField,
      CheckListField,
      DateBoxField,
      TextAreaField,
      ImageField,
      SignatureField,
      TextBoxField
    }
  };
</script>

<style scoped lang="scss">
  .loading-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .padding-20 {
    padding: 20px;
  }

  .padding-10 {
    padding: 10px;
  }

  .form-container {
    padding-left: 40px;
    padding-right: 40px;
    transition-duration: 0.2s;
    background-color: white;

    @media (max-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin-top: 60px; // offset of fixed Header css
  }

  h2 {
    background-color: #f2f2f2;
    padding: 8px 20px;
    color: #4a4a4a;
    font-size: 16px;
  }

  hr {
    color: lightgrey;
  }

  .success-message {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .form-body {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .form-actions {
    display: flex;
    flex-direction: column;
  }

  .form-actions {
    button {
      @media (min-width: 768px) {
        width: 100px;
      }
    }
  }

  .form-field {
    display: flex;
    flex-direction: column;
    clear: both;
    margin-top: 1em;
  }
</style>
